import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { withRouter } from "react-router";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Navigation from "../Navigation/Navigation";
import PagesComponent from "./Template11Pages/PagesComponent";
import RoomTypeComponent from "./Template11Pages/RoomTypeComponent";
import SubItemPagesComponent from "./Template11Pages/SubItemPagesComponent";
import media from "../global/media";
import WatchMyRateWidget from "../Widgets/WatchMyRateWidget";
import BookingWidgetWrapperComponent from "../global/WidgetComponent/BookingWidgetWrapperComponent";

const HeaderWrapper = styled.div`
  position: relative;
  ${media.md} {
  }
  width: 100%;
  z-index: 100;
`;
const MainDiv = styled.div`
  transition: margin-right 0.5s;
  font-size: 12px;
`;

const Template = ({ data }) => {
  // const [footerScroll, setFooterScroll]= useState(false);
  const [navigationToggle, setNavigationToggle] = useState("none");
  const [navigationSticky, setNavigationSticky] = useState(false);
  const [subPage, setSubPage] = useState(0);
  const onNavClick = (value, scroll = true) => {
    setNavigationToggle(
      value ? "none" : navigationToggle === "none" ? "block" : "none"
    );

    if (navigationToggle === "none") {
      // document.getElementById("navigation").style.width = "250px";
      // document.getElementById("main").style.marginRight = "250px";
      document.getElementById("navigation").style.left = "0";
      document.getElementById("navigation").style.transition =
        "all 0.5s ease-in-out";
      document.getElementById("headerWrapper").classList.add("shiftHeader");
      document
        .getElementById("navbarOverlap")
        .classList.add("menu-overlay-active");
      document.getElementById("navBarSub").style.transform =
        "translate3d(100%,0,0)";
    } else {
      // document.getElementById("navigation").style.width = "0";
      // document.getElementById("main").style.marginRight = "0";
      document.getElementById("navigation").style.left = "-100%";
      document
        .getElementById("navbarOverlap")
        .classList.remove("menu-overlay-active");
      document.getElementById("headerWrapper").classList.remove("shiftHeader");
      if (scroll === true) {
        window.scrollTo(0, 0);
      }
    }
  };
  const openSubMenu = (value) => {
    setSubPage(value);
    document.getElementById("navBarSub").style.transform = "translate3d(0,0,0)";
  };
  const backMenu = () => {
    document.getElementById("navBarSub").style.transform =
      "translate3d(100%,0,0)";
  };
  const footerTop = () => {
    // console.log("hello");
    window.scrollTo(0, 0);
  };
  function handleScroll(event) {
    if (
      document.body.scrollTop > 100 ||
      document.documentElement.scrollTop > 100
    ) {
      document.getElementById("headerWrapper").classList.add("sticky");
      // document.getElementById("sharpSvg").style.display="none";
      document.getElementById("active-link").classList.add("displayBtn");
    } else {
      document.getElementById("headerWrapper").classList.remove("sticky");
      document.getElementById("active-link").classList.remove("displayBtn");
      // document.getElementById("sharpSvg").style.display="block";
    }
  }

  function sliderShow(event) {
    document.getElementById("sliderWrapper").classList.add("slider-show");
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("scroll", handleScroll);
    window.addEventListener("load", () => {
      if (document.getElementById("sliderWrapper")) {
        setTimeout(sliderShow, 4000);
      }
    });
  }, []);

  useEffect(() => {
    handleScroll();
  }, [navigationToggle]);

  const accommodationSlugName = data.menuLinks.menuLink.filter((item) =>
    item.pageId === "2" ? item.menuSlugName : ""
  );
  //console.log(accommodationSlugName[0]["menuSlugName"]);

  return (
    <div>
      <BookingWidgetWrapperComponent
        propertyName={data.propertyDetails.propertyName}
        primaryColor={"#fff"}
        secondaryColor={"#81B5B5"}
        textColor={"#fff"}
        backgroundColor={"#81B5B5"}
        buttonColor={"#fff"}
        borderColor={"#7e7e7e"}
        datePickerColor={"#7e7e7e"}
        widgetBg={"#81B5B5"}
        roomBgColor={"#fff"}
        roomTextColor={"#81B5B5"}
        roomHeadBg={"#81B5B5"}
        roomHeadText={"#fff"}
        roomContentBg={"#fff"}
        roomContentText={"#0F172B"}
        buttonBgColor={"#81B5B5"}
      />
      {data.watchMyRateWidget === "1" && (
        <WatchMyRateWidget
          propertyId={data.propertyDetails.propertyID}
          bookingEngine={data.bookingEngine}
          accessKey={data.watchMyRateWidgetAccessKey}
        />
      )}
      <Navigation
        data={data}
        onNavClick={onNavClick}
        navigationSticky={navigationSticky}
        templateId={data.templateId}
        navigationToggle={navigationToggle}
        menuLinks={data.menuLinks.menuLink}
        propertyId={data.propertyDetails.propertyID}
        propertyDetails={data.propertyDetails}
        reviewMinderWidget={data.reviewMinderWidget}
        openSubMenu={openSubMenu}
        subPage={subPage}
        backMenu={backMenu}
      />
      <MainDiv id="main">
        <HeaderWrapper>
          <Header
            templateId={data.templateId}
            propertyDetails={data.propertyDetails}
            colorConfiguration={data.colorConfiguration}
            onNavClick={onNavClick}
            navigationToggle={navigationToggle}
            bookingEngine={data.bookingEngine}
            whiteLabelURL={data.whiteLabelURL}
          />
        </HeaderWrapper>

        {data.menuLinks.menuLink.map((item, index) => {
          return (
            <Route
              exact
              key={index}
              path={item.menuSlugName}
              component={() => (
                <PagesComponent
                  propertyName={data.propertyDetails.propertyName}
                  data={item}
                  templateData={data}
                />
              )}
            />
          );
        })}

        {data.roomTypes.roomType.map((item, index) => {
          const slug = item.RoomTypeName;
          return (
            <Route
              exact
              key={index}
              path={accommodationSlugName[0]["menuSlugName"] + slug.slugName}
              component={() => (
                <RoomTypeComponent data={item} templateData={data} />
              )}
            />
          );
        })}

        {data.multiPagesMain.mainPage &&
          data.multiPagesMain.mainPage.map((item1, index) => {
            return (
              item1.externalPages === "1" &&
              item1.multiPagesSub &&
              item1.multiPagesSub.subPage &&
              item1.multiPagesSub.subPage.map((item, subIndex) => {
                const menuFound = data.menuLinks.menuLink.filter(
                  (i) => i.MenuLinkAdditonalPageID === item1.websitepageid
                );

                return (
                  <Route
                    exact
                    key={subIndex}
                    path={menuFound[0].menuSlugName + item.websiteSlugName}
                    component={() => (
                      <SubItemPagesComponent
                        data={item}
                        templateData={data}
                        menuFound={menuFound[0]}
                      />
                    )}
                  />
                );
              })
            );
          })}

        <Footer
          data={data}
          templateId={data.templateId}
          contactInfo={data.propertyDetails.contactInfo}
          socialLinks={data.propertyDetails.propertySociallinks}
          menuLinks={data.menuLinks.menuLink}
          propertyDetails={data.propertyDetails}
          colorConfiguration={data.colorConfiguration}
          propertyImages={data.propertyImages}
          // onNavClick={onNavClick}
          footerTop={footerTop}
          reviewMinderWidget={data.reviewMinderWidget}
        />
      </MainDiv>
    </div>
  );
};

const TemplateWrapper = withRouter(Template);

const Website11Template = (props) => {
  return (
    <Router basename={`${process.env.PUBLIC_URL}`}>
      <TemplateWrapper {...props} />
    </Router>
  );
};

export default Website11Template;
