import React, { useState, useEffect } from "react"
import styled from "styled-components"
import DatePicker from "react-datepicker"
import { addDays } from "date-fns"
import "react-datepicker/dist/react-datepicker.css"
import { formatDate, formatDate1 } from "../global/functions"
import { fetchWatchMyRate } from "../providers/api"
import media from "../global/media"
import data from "../../hotelData.json"
import LoadingSpinner from "./LoadingSpinner"

const WatchMyRateWrapper = styled.div``;
const DisplayText = styled.div`
  position: fixed;
  /* display:${(props) => (props.openBox ? "none" : "block")}; */
  z-index: 300;
  right: ${(props) => (props.openBox ? "-95px" : "-55px")};
  background: ${(props) => (props.bgColor ? "open.bgColor" : " #32af91")};
  color: #fff;
  padding: 10px;
  top: ${(props) => (props.openBox ? "50%" : "50%")};
  left: ${(props) => (props.openBox ? "" : "auto")};
  cursor: pointer;
  transform: rotate(270deg) !important ;
  transition: all 0.5s ease-in-out;
`;

const DisplayTextOpen = styled.div`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background: ${(props) => (props.bgColor ? "open.bgColor" : " #32af91")};
  color: #fff;
  text-align: center;
  cursor: pointer;
`;

const OpenBoxWrapper = styled.div`
  position: fixed;
  z-index: 101;
  font-size: 12px;
  background: #fff;
  width: 320px;
  color: #000;
  right: -320px;
  top: 100px;
  border-radius: 5px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  transition: right 0.5s;

  &.is-open {
    right: 0;
  }
  /* ${media.sm} {
    right: 50px;
  } */
`;
const OpenBoxWrapperHeader = styled.div`
  background: #eee;
  padding: 20px;
  border-radius: 5px 5px 0 0;
  width: 100%;
  h3 {
    color: #32af91;
    margin-bottom: 10px;
    font-size: 16px;
    /* text-align:center; */
  }
`;
const CheckInWidgetHeader = styled.div`
  display: flex;
  flex-wrap: no-wrap;

  div {
    width: 50%;
    padding-bottom: 5px;
    /* text-align:center; */
  }
`;
const CheckInOutWidgetWrapper = styled.div`
  display: flex;
  border: solid 1px #ccc;
  border-radius: 5px;
  background: #fff;
  padding: 5px;
  overflow: hidden;

  .react-datepicker-wrapper {
    width: 50%;
    input {
      border: 0;
      /* text-align:center; */
      outline: none;
      &:focus {
        outline: 0;
      }
    }
  }
  .moveLeft {
    left: 120px;
  }
`;
const CloseIcon = styled.div`
  position: absolute;
  cursor: pointer;
  top: 13px;
  right: 15px;
  color: #eee;
  font-size: 16px;
`;

const OpenBoxWrapperContent = styled.div`
  min-height: 100px;
  background: #fff;
  position: relative;
`

const OpenBoxWrapperFooter = styled.div`
  background: #fff;
  border-radius: 0 0 5px 5px;
  padding: 15px;
  text-align: center;
`;

const NormalItem = styled.div`
  padding: 10px 15px;
  background: #eee;
  border-bottom: solid 1px #999;
  span {
    float: right;
  }
`;

const SpecialItem = styled.div`
  padding: 10px 15px;
  background: #fff;
  text-align: center;
  border-bottom: dashed 1px #fff;
`;
const BookNowBox = styled.div`
  background-color: #32af91;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  margin-top: 10px;
  .wmrPrice {
    flex: 1;
    padding: 6px;
    color: #fff;
    line-height: 14px !important;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .wmrBtn {
    width: 64px;
    display: flex;
    align-items: center;
    flex: 1;
    vertical-align: top;
    text-align: center;
    font-size: 12px !important;
    text-decoration: none;
    color: #fff;
    padding: 6px;
  }
`;

const WatchMyRateWidget = ({ propertyId, bookingEngine, accessKey }) => {
  const [openBox, setOpenBox] = useState(false)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(addDays(new Date(), 1))
  const [resultData, setResultData] = useState([])
  // const [moveArrow, setMoveArrow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [datePickerOpen, setDatePickerOpen] = useState(false)
  const [edatePickerOpen, seteDatePickerOpen] = useState(false)

  const handleStartDate = date => {
    setStartDate(date)
    setLoading(true)
    setEndDate(addDays(date, 1))
    setDatePickerOpen(false)
  }
  const handleEndDate = date => {
    // setMoveArrow(true)
    setEndDate(date)
    setLoading(true)
    seteDatePickerOpen(false)
  }
  // useEffect(()=>{
  //   const triangle = document.getElementsByClassName("react-datepicker__triangle")[0]
  //   console.log(triangle)
  //   if(moveArrow && triangle){
  //     triangle.classList.add("moveLeft")
  //   }else if(triangle){
  //     triangle.classList.remove("moveLeft")
  //   }

  // },[moveArrow])

  const handleCheckDeals = () => {
    if (propertyId !== "" && startDate !== undefined && endDate !== undefined) {
      fetchWatchMyRate(propertyId, formatDate(startDate), formatDate(endDate), bookingEngine, accessKey).then(data => {
        setResultData(data[formatDate(startDate)])
        setLoading(false)
      })
    }
  };

  const handleBookNowClick = () => {
    window.location.href =
      data.bookingEngine === "I"
        ? `https://secure.staah.com/common-cgi/package/packagebooking.pl?propertyId=${propertyId}&header=&flexible=&frame=&source=&layout=&Ln=en&ccode=&checkIn=${formatDate1(
          startDate
        )}&checkOut=${formatDate1(endDate)}`
        : `https://staahmax.staah.net/be/indexpackdetail?propertyId=${propertyId}&promoCode=WMR&${formatDate1(
          startDate
        )}&checkOut=${formatDate1(endDate)}`;
    return false;
  };
  const openWidgetBox = () => {
    setOpenBox(!openBox);
  };

  useEffect(() => {
    handleCheckDeals();
  }, [endDate]);

  return (
    <WatchMyRateWrapper>
      <DisplayText openBox={openBox} onClick={openWidgetBox}>
        Your Deal Today
      </DisplayText>

      <OpenBoxWrapper openBox={openBox} className={openBox ? "is-open" : ""}>
        <DisplayTextOpen>Your Deal Today</DisplayTextOpen>
        <OpenBoxWrapperHeader>
          <h3>Lowest Rate Available</h3>
          <CloseIcon onClick={openWidgetBox}>X</CloseIcon>
          <CheckInWidgetHeader>
            <div>Check-in</div>
            <div>Check-out</div>
          </CheckInWidgetHeader>
          <CheckInOutWidgetWrapper>
            {console.log("startDate", startDate)}
            {console.log("endDate", endDate)}

            <div class="check-in" onClick={() => setDatePickerOpen(!datePickerOpen)}>
              <DatePicker
                selected={startDate}
                className="datepicker-input"
                onChange={(date) => handleStartDate(date)}
                selectsStart
                startDate={startDate}
                inputProps={{ readOnly: true }}
                endDate={endDate}
                minDate={new Date()}
                dateFormat={"dd/MM/yyyy"}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                readOnly={true}
                onClickOutside={() => setDatePickerOpen(false)}
                open={datePickerOpen}
              />
            </div>

            <div class="check-out" onClick={() => seteDatePickerOpen(!edatePickerOpen)}>
              <DatePicker
                selected={endDate}
                className="datepicker-input"
                inputProps={{ readOnly: true }}
                onChange={(date) => handleEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat={"dd/MM/yyyy"}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                readOnly={true}
                onClickOutside={() => seteDatePickerOpen(false)}
                open={edatePickerOpen}
                popperModifiers={{
                  preventOverflow: {
                    enabled: true,
                  },
                }}
              />
            </div>
          </CheckInOutWidgetWrapper>
        </OpenBoxWrapperHeader>

        <OpenBoxWrapperContent>
          {loading &&
            <LoadingSpinner size="100%" text="Loading" />
          }
          {(resultData && !loading) &&
            resultData.length > 0 &&
            resultData.map((item, index) => {
              return item.channelId === "3" || item.channelId === "14" ? (
                <SpecialItem key={index}>
                  {item.channelName}
                  <BookNowBox onClick={handleBookNowClick}>
                    <span class="wmrPrice">
                      <b class="wmrRate">
                        {item.currency} {item.rate}
                      </b>
                    </span>
                    <span class="wmrBtn">
                      <b> {item.otabuttonlable || "Book Now"} </b>
                    </span>
                  </BookNowBox>
                </SpecialItem>
              ) : (
                <NormalItem key={index}>
                  {item.channelName}{" "}
                  <span>
                    {item.currency} {item.rate}
                  </span>
                </NormalItem>
              );
            })}
          {!resultData && <div>no data</div>}
        </OpenBoxWrapperContent>

        <OpenBoxWrapperFooter>Deals May Vary</OpenBoxWrapperFooter>
      </OpenBoxWrapper>
    </WatchMyRateWrapper>
  );
};

export default WatchMyRateWidget;
