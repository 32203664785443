import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import data from "./hotelData.json";
import Website1Template from "./components/Template/Template1";
import Website2Template from "./components/Template/Template2";
import Website3Template from "./components/Template/Template3";
import Website4Template from "./components/Template/Template4";
import Website5Template from "./components/Template/Template5";
import Website6Template from "./components/Template/Template6";
import Website7Template from "./components/Template/Template7";
import Website8Template from "./components/Template/Template8";
import Website9Template from "./components/Template/Template9";
import Website10Template from "./components/Template/Template10";
import Website11Template from "./components/Template/Template11";
import MessengerCustomerChat from "react-messenger-customer-chat";
import { WidgetClose } from "./components/global/constants";
// import "./css/style.css";
import "./components/Css/stylesheet.css"

const App = () => {
  useEffect(() => {
    WidgetClose();
  }, []);
  if (data.propertyDetails.googleAnalyticsId) {
    // console.log("here");
    ReactGA.initialize(data.propertyDetails.googleAnalyticsId);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  if (data.propertyDetails.googleTagManagerId) {
    const tagManagerArgs = {
      gtmId: data.propertyDetails.googleTagManagerId,
    };
    TagManager.initialize(tagManagerArgs);
  }

  // useEffect(()=>{
  //   require("./components/Css/stylesheet.css")
  // },[])

  return (
    <div>
      {data.templateId === "1" && <Website1Template data={data} />}
      {data.templateId === "2" && <Website2Template data={data} />}
      {data.templateId === "3" && <Website3Template data={data} />}
      {data.templateId === "4" && <Website4Template data={data} />}
      {data.templateId === "5" && <Website5Template data={data} />}
      {data.templateId === "6" && <Website6Template data={data} />}
      {data.templateId === "7" && <Website7Template data={data} />}
      {data.templateId === "8" && <Website8Template data={data} />}
      {data.templateId === "9" && <Website9Template data={data} />}
      {data.templateId === "10" && <Website10Template data={data} />}
      {data.templateId === "11" && <Website11Template data={data} />}

      {data.facebookWidget === "1" && (
        <MessengerCustomerChat
          pageId={data.facebookParameters.pageId}
          appId={data.facebookParameters.appId}
          version={data.facebookParameters.version}
          shouldShowDialog="true"
          htmlRef={window.location.pathname}
          loggedInGreeting={data.facebookParameters.loggedInGreeting}
          loggedOutGreeting={data.facebookParameters.loggedOutGreeting}
        />
      )}
    </div>
  );
};

export default App;
